<template>
    <div v-if="loading" class="loader-container">
        <div class="loader-mask">
            <v-progress-circular 
                color="#5A4FA2" 
                indeterminate 
                size="50">
            </v-progress-circular>
        </div>
    </div> 
    <div v-else>
        <div v-if="!iframe">
            <p style="font-size: 30px; text-align: center; margin: 20px 0 40px 0;">
                TU HIPOTECA <span v-if="prescoringData.origen === 'funnel100'"> 100% </span> PARECE <span style="color:rgb(102, 205, 102)">VIABLE</span>
            </p>
            <h4 style="text-align: center;">Has indicado que puedes aportar 
                <span style="color: #67C9D3" v-if="scoringData.resultado.prescoring_data.porcentaje == 80"> más de {{ twentyFivePercent(scoringData.resultado.prescoring_data.precio_compra) | currency }}€,</span> 
                <span style="color: #67C9D3" v-else> entre {{ fifteenPercent(scoringData.resultado.prescoring_data.precio_compra) | currency  }}€ y {{ twentyFivePercent(scoringData.resultado.prescoring_data.precio_compra) | currency  }}€,</span>
                si introduces el valor exacto de tu aportación, podemos calcular el precio de tu hipoteca y las diferentes cuotas que te podemos ofrecer</h4>
            <div class="bloque-aportacion">
                <p class="form-title">Aportación de ahorros propios/familiares a la operación:</p>
                <v-currency-field
                    v-model="aportacion"
                    outlined
                    append-icon="mdi-currency-eur"
                    dense
                ></v-currency-field>
            </div>
            <p v-if="showError" style="font-size: small; margin-bottom: 8px; display: flex; justify-content: flex-end;">Introduce un valor a la aportación</p>
            <div style="display: flex; justify-content: flex-end; margin-bottom: 30px;">
                <button class="button-primary_element" @click="handleButtonClick" :disabled="loadingCuotas">
                    <span class="button-primary_text">Calcular mi hipoteca</span>
                </button>
            </div>
        </div>
        <div v-else>
            <p style="font-size: 30px; text-align: center; margin: 20px 0 40px 0;">
                LA HIPOTECA <span v-if="prescoringData.origen === 'funnel100'"> 100% </span> PARECE <span style="color:rgb(102, 205, 102)">VIABLE</span>
            </p>
            <h4 style="text-align: center;">El cliente ha indicado que puede aportar 
                <span style="color: #67C9D3" v-if="scoringData.resultado.prescoring_data.porcentaje == 80"> más de {{ twentyFivePercent(scoringData.resultado.prescoring_data.precio_compra) | currency }}€</span> 
                <span style="color: #67C9D3" v-else> entre {{ fifteenPercent(scoringData.resultado.prescoring_data.precio_compra) | currency  }}€ y {{ twentyFivePercent(scoringData.resultado.prescoring_data.precio_compra) | currency  }}€</span>
            </h4>
            <br/>
        </div>
        <!-- BLOQUE CASITAS -->
        <div v-if="showCasita">
            <div>
                <div class="data-container" >
                    <div style="position: absolute; top: 0; left: 0; z-index: 1; " class="casitas-img_my">
                        <img src="../../assets/casa.png" style="width: 140px;"/>
                    </div>
                    <div class="casa-text_container" style="margin-bottom: 20px;">
                        <p class="casa-text">Precio de la Vivienda: {{scoringData.resultado.precio_compra | currency}}€ </p>
                        <p class="casa-text">Importe de Hipoteca: 
                            <span v-if="!loadingCuotas">{{scoringData.resultado.importe | currency}}€</span>
                            <v-progress-circular v-if="loadingCuotas" color="#5A4FA2" indeterminate size="20"></v-progress-circular>
                        </p>
                        <p class="casa-text">Financiación al  
                            <span v-if="!loadingCuotas">{{formatNum(scoringData.resultado.financiacion)}} %</span>
                            <v-progress-circular v-if="loadingCuotas" color="#5A4FA2" indeterminate size="20"></v-progress-circular>
                        </p>
                    </div>
                </div>  
                <p class="form-title" style="text-align: center;">Cuota mensual</p>
                <div class="table_type">
                    <div class="bloque-interes">
                        <p class="interes-titulo">FIJO</p>
                        <p class="interes"> {{ formatNum2Decimals(scoringData.resultado.cuotas.fijo.interes) }}%</p>
                        <p class="cuota">
                            <span style="font-weight: normal; font-size: 14px">Desde </span> 
                            <span v-if="!loadingCuotas">{{scoringData.resultado.cuotas.fijo.cuota }}€ </span>
                            <v-progress-circular v-if="loadingCuotas" color="#5A4FA2" indeterminate size="20"></v-progress-circular>
                        </p>
                    </div>
                    <div class="bloque-interes">
                        <p class="interes-titulo">MIXTO</p>
                        <p class="interes"> {{ formatNum2Decimals(scoringData.resultado.cuotas.mixto.interes_fijo)}}% <span class="interes_anyos">({{scoringData.resultado.cuotas.mixto.interes_fijo_anyos }} años)</span></p>
                        <p class="cuota">
                            <span style="font-weight: normal; font-size: 14px">Desde </span>
                            <span v-if="!loadingCuotas">{{scoringData.resultado.cuotas.mixto.cuota }}€</span> 
                            <v-progress-circular v-if="loadingCuotas" color="#5A4FA2" indeterminate size="20"></v-progress-circular>
                        </p>
                    </div>
                    <div class="bloque-interes">
                        <p class="interes-titulo">VARIABLE</p>
                        <p class="interes">Euribor + {{ formatNum2Decimals(scoringData.resultado.cuotas.variable.interes_diferencial)}}%</p>
                        <p class="cuota">
                            <span style="font-weight: normal; font-size: 14px">Desde </span>
                            <span v-if="!loadingCuotas">{{scoringData.resultado.cuotas.variable.cuota }}€*</span> 
                            <v-progress-circular v-if="loadingCuotas" color="#5A4FA2" indeterminate size="20"></v-progress-circular>
                        </p>
                    </div>
                </div>
            </div>

            <div class="datos-container">
                <p style="font-size: small;">*Cuota mensual durante el primer año</p>
                <p style="font-size: small;" v-if="scoringData.resultado.gastos.itp">
                    Total Gastos de la operación (ITP {{scoringData.resultado.gastos.itp.porcentaje}}%, Notaría y Registro): 
                    <span>{{scoringData.resultado.gastos.total | currency}}€</span>
                </p>  
                <p style="font-size: small;" v-if="scoringData.resultado.gastos.ajd">
                    Total Gastos de la operación (AJD {{scoringData.resultado.gastos.ajd.porcentaje}}%, IVA {{scoringData.resultado.gastos.iva.porcentaje}}%, Notaría y Registro): 
                    <span>{{scoringData.resultado.gastos.total | currency}}€</span>
                </p>  
            </div>
            <!-- BLOQUE FINAL CON BTN -->
            <div v-if="!iframe">
                <div style="margin:25px 0" v-if="scoringData.mostrar_free">
                    <h4 style="text-align: center;">¡Ya podemos empezar! Con la aportación definida la financiación es inferior al 80%, así que nuestros servicios van a ser gratuitos. 
                        <br>Un experto hipotecario de nuestro equipo se pondrá en contacto contigo, pero puede avanzar por tu cuenta subiendo la documentación a tu zona privada
                    </h4>
                    <div class="d-flex flex-column justify-center mt-5">
                        <button class="button-primary_element" @click="goToZP">Acceder a mi zona privada</button>
                    </div>
                    <div v-if="scoringData.staff && scoringData.staff.phone" class="d-flex flex-column justify-center mt-5a" style="text-align: center; margin-top:25px;">
                        <h4>También puedes ponerte en contacto con nosotros a través de whatsapp</h4>
                        <button class="button-primary_element whatsapp-btn" @click="enlaceWhatsapp"><v-icon big class="mdi mdi-whatsapp"></v-icon> Whatsapp</button>
                    </div>
                </div>
                <div style="margin:25px 0" v-else>
                    <h4 style="text-align: center;">¡Ya podemos empezar! Hemos recibido tu solicitud y un experto hipotecario de nuestro equipo se pondrá en contacto contigo.
                        <br> Para mayor comodidad puedes escribirnos por whatsap o solicitarnos una llamada telefónica cuando mejor te vaya
                    </h4>
                    <div class="botones-success">
                        <button v-if="scoringData.staff && scoringData.staff.phone" class="button-primary_element whatsapp-btn" @click="enlaceWhatsapp"><v-icon big class="mdi mdi-whatsapp"></v-icon> Whatsapp</button>
                        <button class="button-primary_element" @click="toggleCuadroCuando">Solicitar llamada <v-icon big class="mdi mdi-phone"></v-icon></button>
                    </div>
                    <div class="cuando-llamamos-block" v-if="cuando && !solicitud_ok">
                        <p>Dinos cuando quieres que te llamemos:</p>
                        <v-text-field 
                            class="cuando-text"
                            v-model="mensaje_llamada"
                            outlined
                            dense
                        ></v-text-field>
                        <div style="display: flex; justify-content: flex-end; margin-bottom: 30px;">
                            <button class="button-primary_element" @click="enviarSolicitudLlamada">
                                <span class="button-primary_text">Enviar</span>
                            </button>
                        </div>
                    </div>
                    <div class="mensaje-llamada-ok" v-if="solicitud_ok">
                        <p>Solicitud enviada correctamente, nuestro analista se pondrá en contacto contigo según tus indicaciones</p>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="debug" @click="goToBegin">
            Revisar datos
        </button>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        name: 'SuccessView',
        data() {
            return {
                aportacion: 0,
                loadingCuotas: false,
                showCasita: false,
                showError: false,
                initialLoad: true,
                cuando: false,
                mensaje_llamada: null,
                solicitud_ok: false
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            this.showCasita = !!this.iframe;
        },
        computed: {
            ...mapState([
                'prescoringData',
                'scoringData',
                'debug',
                'loading',
                'iframe'
            ])
        },
        methods: {           
            //DEBUG
            ...mapMutations([
                'SET_ACTIVE_TAB',
                'SET_PRESCORING_DATA',
            ]),
            ...mapActions([
                'sendPrescoringData',
                'sendSolicitudLlamada',
                'saveAccionSuccess'
            ]),
            toggleCuadroCuando(){
                this.cuando = !this.cuando;
            },
            fifteenPercent(value) {
                return value * 0.2;
            },
            twentyFivePercent(value) {
                return value * 0.3;
            }, 
            formatNum2Decimals(value) {
                return value.toFixed(2);
            },
            formatNum(value) {
                let formattedValue = value.toFixed(2); 
                if (formattedValue.endsWith('.00')) {
                    return Math.floor(value); // Elimina los decimales si son .00
                } else if (formattedValue.endsWith('0')) {
                    return parseFloat(formattedValue).toFixed(1); // Mantiene un decimal si el segundo decimal es 0
                }
                return formattedValue;
            },            
            goToBegin(){
                this.SET_ACTIVE_TAB('InitialView');
            },
            goToZP(){
                this.saveAccionSuccess('ZONA_PRIVADA');
                window.location.href = `https://mi.helloteca.com/?pId=${encodeURI(this.scoringData.portal_user_token)}`;
            },
            async enviarSolicitudLlamada() {
                try{
                    this.saveAccionSuccess('LLAMADA');
                    await this.sendSolicitudLlamada( { mensaje_llamada:this.mensaje_llamada } );
                } finally {
                    this.solicitud_ok = true;
                }
            },
            enlaceWhatsapp(){
                this.saveAccionSuccess('WHATSAPP');
                const url = `https://wa.me/+34${this.scoringData.staff.phone}?text=Me interesa esta hipoteca`;
                window.open(url, "_blank");
            },
            async handleButtonClick() {
                if (this.aportacion === 0) {
                    this.showError = true;
                    if (this.initialLoad) {
                        this.showCasita = false;
                        this.initialLoad = false; 
                    }
                } else {
                    this.showCasita = true;
                    await this.llamadaApi();
                    this.showError = false;
                    this.initialLoad = false; 
                    this.loadingCuotas = false;
                }
            },            
            async llamadaApi(){
                let params = this.prescoringData;
                params.aportacion = this.aportacion;
                params.recalculando = true;
                this.loadingCuotas = true;
                this.SET_PRESCORING_DATA(params);
                //console.log("params desde success", this.prescoringData)
                try{
                    await this.sendPrescoringData(this.prescoringData);
                } finally {
                    this.loadingCuotas = false;
                }
            }
        }
    }
</script>

<style scoped>
    .whatsapp-btn{
        background-color: #25d366;
        border-color: #25d366;
        text-align: center;
        text-decoration: none;
        color: #fff;
    }
    .whatsapp-btn i{
        margin-left: 5px;
        color: #fff;
    }
    .whatsapp-btn:hover{
        background-color: #fff;
        color: #25d366;
    }
    .whatsapp-btn:hover i{
        color: #25d366;
    }
    .botones-success{
        display: flex;
        gap: 10px;
        justify-content: space-between;
        margin-top: 20px;
    }
    .botones-success button,
    .botones-success a{
        flex: 1;
    }
    .botones-success button i{
        color: #fff;
        margin-left: 10px;
        font-size: 21px;
    }
    .botones-success button:hover i{
        color: #5A4FA2;
    }
    .botones-success a{
        justify-content: center;
    }
    .cuando-llamamos-block{
        margin-top: 25px;
    }
    .cuando-llamamos-block .cuando-text{
        margin-bottom: 10px !important;
    }
    .mensaje-llamada-ok{
        margin-top: 25px;
    }
    .mensaje-llamada-ok .mensaje{
        font-style: italic;
        color: #67c9d3;
    }

/* CASITAS */
    .bloque-aportacion{
        margin: 15px 0 30px;
    }
    .bloque-aportacion .form-title{
        margin-bottom: 5px;
        font-size: 16px;
    }
    .data-container{
        display: flex;  
        justify-content: flex-end;        
    }
    .casa-text_container{
        margin-top: 30px;
    }
    .casa-text{
        font-size: 18px;
    }
    @media (min-width: 550px){
        .data-container{
            display: flex; 
            align-items: flex-start; 
            position: relative;
        }
        .casa-text_container{
            flex-grow: 1;
            text-align: right;
        }
        .casa-text{
            font-size: 22px;
        }
    }
    @media (max-width: 767px) {
        .casitas-img_my {
            display: none;
        }
    }

    /* DATOS */
    .datos-container{
        font-size: 18px; 
        margin: 30px  0;
    }

    @media (min-width: 550px) {
        .datos-container{
            display:flex; 
            flex-direction: column;  
            justify-content: flex-end; 
            align-items: flex-end; 
            font-size: 20px; 
            margin: 30px  0;
        }
    }
</style>