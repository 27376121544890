import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const apiHellotecaProd = 'https://api.helloteca.com';
const apiHellotecaPre = 'https://api-dev-dot-helloteca-com.ew.r.appspot.com';
const apiHellotecaLocal = 'http://localhost:7999';
const xWebKeyProd = 'prod_public_3eTH95nz7urVNz8ADAInP5PeC3dv_nEfCHayPY5/4ZrUVMvmrw0VBR7bb';
const xWebKeyLocal = 'local_public_develop_team_HkaYN4bK5TszWY0EAJJUM';

/**LOCAL PRE-PRODUCTION O PRODUCTION VARS*/
var entorno = 'PRO'; //PRE,PRO,LOCAL
/** ^^^^^^^^^^^^^^^ */
var xWebKey = entorno == 'LOCAL'?xWebKeyLocal:xWebKeyProd;
var apiHelloteca = entorno == 'PRE' ? apiHellotecaPre : (entorno == 'LOCAL'? apiHellotecaLocal : apiHellotecaProd);
/***********/

export default new Vuex.Store({
    state: {  
        vars:[],
        lead_id: null,
        iframe: null,
        utmParams:{},
        activeTab: null,
        completedTabs:0,
        loading: false,
        isFilledOperation: true,

        prescoringData: {
            producto: 'habitual',
            precio_compra: null,
            ccaa: null,
            id_parent: null,
            vivienda_nueva: null,
            estado_proceso: null,
            aportacion: null,
            producto_hipotecario: null,
            dgh: {
                avalista: null,
                avalista_tipo: '',
                avalista_valor_vivienda: null,
                avalista_hipoteca: null,
                avalista_hipoteca_valor: null
            },
            titulares: [
                {
                    edad: 45, 
                    contrato: '', 
                    antiguedad: null
                },
                {
                    edad: null, 
                    contrato: '', 
                    antiguedad: null
                }
            ],
            total_ingresos: null,
            total_gastos: null,
            aval_ico: null,
            nombre_completo: '',
            telefono: '',
            email: '',
            utms: {
                utm_source: '',
                utm_campaign: '',
                utm_medium: '',
                utm_content: '',
                utm_term: ''
            },
            origen: '',
            porcentaje: null,
        },   
        tipo_operacion: 'funnel8090',
        debug: false,
        scoringData: {},
        mortgageDetails: {}
    },
    mutations: {
        SET_VARS(state, value){
            state.vars = value
        },
        SET_LEAD_ID(state, value){
            state.lead_id = value
        },
        SET_UTM_PARAMS(state,value){
            state.utmParams = value;
        },
        SET_IFRAME(state,value){
            state.iframe = value;
        },
        SET_ORIGEN(state,value){
            state.prescoringData.origen = value;
        },
        SET_PARENT(state,value){
            state.prescoringData.id_parent = value;
        },
        SET_CCAA(state,value){
            state.prescoringData.ccaa = value;
        },
        SET_PRODUCTO(state,value){
            state.prescoringData.producto = value;
        },
        SET_ACTIVE_TAB(state, tabName){
            state.activeTab = tabName
        }, 
        SET_COMPLETED_TABS(state,value){
            state.completedTabs = value;
        },
        SET_LOADING(state,value){
            state.loading = value
        },
        SET_IS_FILLED(state,value){
            state.isFilledOperation = value;
        },
        SET_TIPO_OPERACION(state,value){
            state.tipo_operacion = value;
        },
        SET_DEBUG(state,value){
            state.debug = value;
        },
        SET_PRESCORING_DATA(state, payload) {
            if (payload.dgh) state.prescoringData.dgh = { ...state.prescoringData.dgh, ...payload.dgh };
            if (payload.titulares) state.prescoringData.titulares = [...payload.titulares];
            state.prescoringData = { ...state.prescoringData, ...payload };
            //console.log("DATA",state.prescoringData);
        },
        SET_SCORING_DATA(state,value) {
            state.scoringData = value;
        },
        SET_MORTGAGE_DETAILS(state,value) {
            state.mortgageDetails = value;
        },        
    },
    actions: {        
        getVars({commit}){
            axios
            .get(
                `${apiHelloteca}/public/variables?vars=yes_no_field,ccaa,new_build,tipo_vivienda,tipo_contrato,tipo_encontrada,tipo_oferta,tipo_avalista,tipo_prestamo`,
                { headers:
                    {'X-WEB-KEY': xWebKey}
                }
            )
            .then(
                response => {
                    if(response.status){
                        commit('SET_VARS',response.data.data)
                    }else{
                        console.log('Error: ' , response);
                    }
                }
            )
        },
        setActiveTab({ commit }, tabName) {
            commit('SET_ACTIVE_TAB', tabName);
        },
        async getMortgageDetails({commit, state}, params) {   
            commit('SET_LOADING',true);
            // 80%
            if(state.prescoringData.porcentaje == 80){
                params.precio = params.precio * 0.8;
            }
            // 90%
            if(state.prescoringData.porcentaje == 90){
                params.precio = params.precio * 0.9;
            }
            await axios
            .post(
                `${apiHelloteca}/public/calculator/obtener_cuotas`,
                params,
                { headers: 
                    {'X-WEB-KEY': xWebKey}
                }
            )
            .then(
                response => {
                    commit('SET_LOADING',false);
                    if(response.status){
                        // commit('SET_MORTGAGE_DETAILS',response.data.data);
                        commit('SET_MORTGAGE_DETAILS',response.data.data.cuotas);
                    }else{
                        console.log('Error: ' , response);
                    }
                }
            )        
        },
        updatePrescoringData({ commit }, payload) {
            commit('SET_PRESCORING_DATA', payload);
        },
        getPrescoringData({commit,state}) {
            commit('SET_LOADING',true);
            const urlApi = state.tipo_operacion == 'funnel8090' ? `${apiHelloteca}/public/funnel8090`:`${apiHelloteca}/public/funnel100`;
            axios
                .get(
                    `${urlApi}/leads/${state.lead_id}`,
                    { headers: 
                        {'X-WEB-KEY': xWebKey}
                    }
                )
                .then(
                    response => {
                        commit('SET_LOADING',false);
                        if(response.status){
                            response.data.data.resultado.prescoring_data.aportacion = null;
                            commit('SET_SCORING_DATA',response.data.data);
                            commit('SET_PRESCORING_DATA',response.data.data.resultado.prescoring_data);
                            if(response.data.data.resultado.es_viable === true){
                                commit('SET_ACTIVE_TAB','SuccessView')
                            } else {
                                commit('SET_ACTIVE_TAB','UnsuccessView')
                            }
                        }else{
                            console.log('Error: ' , response);
                        }
                    }
                )
        },
        async saveAccionSuccess( { state}, accion){
            const leadId = state.scoringData.id_lead??null;
            if(leadId === null) return false;
            const params = {accion : accion};
            try{
                const response = await axios.post(
                    `${apiHelloteca}/public/funnel8090/update_accion_success/${leadId}`,
                    params,
                    { headers: 
                        { 'X-WEB-KEY': xWebKey } 
                    }
                );
                if (response.status) {
                    return true;
                } else {
                    console.log('Error: ', response);
                }
            } catch (error) {
                console.error('Error en saveAccionSuccess:', error);
            } finally {
                console.error('Enviada');
            }            
        },
        async sendSolicitudLlamada( { commit, state}, params){
            commit('SET_LOADING', true);
            const leadId = state.scoringData.id_lead??null;
            try{
                const response = await axios.post(
                    `${apiHelloteca}/public/funnel8090/llamada/${leadId}`,
                    params,
                    { headers: 
                        { 'X-WEB-KEY': xWebKey } 
                    }
                );
                if (response.status) {
                    return true;
                } else {
                    console.log('Error: ', response);
                }
            } catch (error) {
                console.error('Error en sendSolicitudLlamada:', error);
            } finally {
                commit('SET_LOADING', false);  
            }
        },
        async sendPrescoringData({ commit, state }, params) {
            commit('SET_LOADING', true);
            const prescoringId = state.scoringData.id_prescoring??null;
            const urlApi = state.tipo_operacion == 'funnel8090' ? `${apiHelloteca}/public/funnel8090`:`${apiHelloteca}/public/funnel100`;
            try {
                // Inserta
                if(!prescoringId){
                    if(state.debug) console.log("NUEVO_LEAD",params);
                    const response = await axios.post(
                        `${urlApi}/leads`,
                        params,
                        { headers: 
                            { 'X-WEB-KEY': xWebKey } 
                        }
                    );
                    if (response.status) {
                        commit('SET_SCORING_DATA', response.data.data);
                        if (response.data.data.resultado.es_viable === true) {
                            commit('SET_ACTIVE_TAB', 'SuccessView');
                        } else {
                            commit('SET_ACTIVE_TAB', 'UnsuccessView');
                        }
                    } else {
                        console.log('Error: ', response);
                    }
                }
                // Actualiza
                else{
                    if(state.debug) console.log("ACTUALIZANDO_LEAD",state.prescoringData);
                    const response = await axios.put(
                        `${urlApi}/leads/${prescoringId}`,
                        state.prescoringData,
                        { headers: 
                            { 'X-WEB-KEY': xWebKey } 
                        }
                    );
                    if (response.status) {
                        commit('SET_SCORING_DATA', response.data.data);
                        if (response.data.data.resultado.es_viable === true) {
                            commit('SET_ACTIVE_TAB', 'SuccessView');
                        } else {
                            commit('SET_ACTIVE_TAB', 'UnsuccessView');
                        }
                    } else {
                        console.log('Error: ', response);
                    }
                }                
            } catch (error) {
                console.error('Error en sendPrescoringData:', error);
            } finally {
                commit('SET_LOADING', false);  
            }
        },
        async solicitarCodigoVerificacion( {state}){
            const telefono = state.prescoringData.telefono??null;
            const email = state.prescoringData.email??null;
            if(telefono === null) return false;
            const params = {"telefono": telefono, "email": email, "canal": "sms"};
            try{
                const response = await axios.post(
                    `${apiHelloteca}/public/otp/enviar_verificacion`,
                    params,
                    { headers: 
                        { 'X-WEB-KEY': xWebKey } 
                    }
                );
                if (response.status) {
                    return response.data.data;
                } else {
                    console.log('Error: ', response);
                }
            } catch (error) {
                console.error('Error en saveAccionSuccess:', error);
                return false;
            }
        },
        async verificarCodigoVerificacion( {state, commit}){
            commit('SET_LOADING', true);
            const telefono = state.prescoringData.telefono??null;
            const codigo = state.prescoringData.codigo??null;
            const email = state.prescoringData.email??null;
            if(telefono === null || codigo === null) return false;
            const params = {"telefono": telefono, "codigo": codigo, "email": email};
            try{
                const response = await axios.post(
                    `${apiHelloteca}/public/otp/validar_verificacion`,
                    params,
                    { headers: 
                        { 'X-WEB-KEY': xWebKey } 
                    }
                );
                if (response.status) {
                    commit('SET_LOADING', false); 
                    return response.data.data.validada;
                } else {
                    console.log('Error: ', response);
                    commit('SET_LOADING', false); 
                    return false;
                }
            } catch (error) {
                console.error('Error en verificarCodigoVerificacion:', error);
                return false;
            }
        },
        async ComprobarVerificacion( {state}){
            const telefono = state.prescoringData.telefono??null;
            const email = state.prescoringData.email??null;
            if(telefono === null) return false;
            const params = {"telefono": telefono, "email": email};
            try{
                const response = await axios.post(
                    `${apiHelloteca}/public/otp/comprobar_verificado`,
                    params,
                    { headers: 
                        { 'X-WEB-KEY': xWebKey } 
                    }
                );
                if (response.status) {
                    return response.data.data;
                } else {
                    console.log('Error: ', response);
                }
            } catch (error) {
                console.error('Error en ComprobarVerificacion:', error);
                return false;
            }
        }
    }
})
